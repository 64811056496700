'use client';
import style from './TagContainer.module.css';
import { useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import ChevronRight from 'public/images/icons/chevronRight.svg';
import searchTrash from 'public/images/icons/searchTrash.svg?url';
import { useRouter } from 'next/navigation';

import { SearchLabel } from 'lib/types';
import { usePathname } from 'next/navigation';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';
import { useSearchProvider } from 'providers/SearchProvider';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { TagAction } from 'providers/savedSearches';

function isActiveSearch(pathName: string, searchId: string | null) {
  return searchId ? pathName.includes(searchId) : false;
}

interface TagContainerProps {
  searches: SearchLabel[];
  tag: string;
  closeNavCB?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isExpanded: boolean;
}

export default function TagContainer({
  tag,
  searches,
  closeNavCB,
  isExpanded,
}: TagContainerProps) {
  const [user] = useAuth();
  const [searchBeingDeleted, setSearchBeingDeleted] =
    useState<SearchLabel | null>(null);
  const pathName = usePathname();
  const searchProvider = useSearchProvider();
  const router = useRouter();

  const handleOnClick = () => {
    const action: TagAction = isExpanded ? 'remove' : 'add';
    searchProvider?.savedSearches?.setOpenedTags(tag, action);
  };

  return (
    <div className={style.tagSearch}>
      <div className={style.tag} onClick={handleOnClick}>
        <ChevronRight
          className={classNames(style.chevron, {
            [style.chevronOpen]: isExpanded,
          })}
          alt="Chevron right"
        ></ChevronRight>
        <span className={style.tagText}>#{tag}</span>
      </div>
      <ul
        className={classNames(style.searchList, {
          [style.searchListShow]: isExpanded,
        })}
      >
        {searches.map((search) => (
          <li
            key={search.id + search.title}
            className={classNames(style.searchListItem, {
              [style.activeSearch]: isActiveSearch(pathName, search.queryId),
            })}
          >
            <Link
              href={`/search/${search.queryId}`}
              className={classNames(style.search)}
              onClick={(ev) => {
                if (closeNavCB) closeNavCB(ev);
                if (search.queryId) searchProvider?.loadSearch(search.queryId);
                //reset inputs error messages follow-up question input value on navigation to other search
                Boolean(searchProvider?.searchForm.getErrorMessage()) &&
                  searchProvider?.searchForm.setErrorMessage('');
                Boolean(
                  searchProvider?.followUpQuestionForm.getErrorMessage(),
                ) && searchProvider?.followUpQuestionForm.setErrorMessage('');
                Boolean(searchProvider?.followUpQuestionForm.getInputValue()) &&
                  searchProvider?.followUpQuestionForm.setInputValue('');
                analytics.clickedPreviousSearch(user, search);
              }}
            >
              {search.title}
            </Link>
            <Image
              className={style.deleteIcon}
              src={searchTrash}
              alt="delete search"
              height={20}
              width={20}
              onClick={() => {
                setSearchBeingDeleted(search);
                analytics.generic(user, 'Delete icon clicked', { search });
              }}
            />
          </li>
        ))}
      </ul>
      <Modal
        className={style.modal}
        isOpen={searchBeingDeleted !== null}
        onCloseCB={() => setSearchBeingDeleted(null)}
        title="Delete Search"
      >
        Are you sure you want to delete this search:{' '}
        <span className={style.deletePopupSearchTitle}>
          {searchBeingDeleted?.title}
        </span>
        <div className={style.deletePopupButtonsContainer}>
          <Button
            className={style.deletePopupButton}
            onClick={() => {
              if (searchBeingDeleted?.queryId) {
                analytics.generic(user, 'Deleted chat', { searchBeingDeleted });
                searchProvider?.savedSearches.remove(
                  searchBeingDeleted.queryId,
                );
              }
              setSearchBeingDeleted(null);
              if (isActiveSearch(pathName, searchBeingDeleted?.queryId ?? '')) {
                router.push('/');
              }
            }}
          >
            Delete
          </Button>
          <Button
            className={style.deletePopupButton}
            onClick={() => {
              setSearchBeingDeleted(null);
              analytics.generic(user, 'Delete chat - cancel clicked', {
                searchBeingDeleted,
              });
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}
