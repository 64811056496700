'use client';
import Button from 'components/Button';
import isMobile from 'is-mobile';
import Image from 'next/image';
import Link from 'next/link';
import { MouseEvent, useRef } from 'react';
import { fetchWithCredentials } from 'utils/fetchWrapper';
import EmptyState from './emptyState';

import { useEffect, useState } from 'react';

import cleeaiLogoV2 from 'public/images/icons/cleeaiLogoV2.svg?url';
import closeIcon from 'public/images/icons/close.svg?url';
import inviteFriendsIcon from 'public/images/icons/inviteFriends.svg?url';
import lightbulbBlueIvy from 'public/images/icons/lightbulbBlueIvy.svg?url';
import compareIcon from 'public/images/icons/llm-compare.svg?url';
import personOutlineIcon from 'public/images/icons/personOutline.svg?url';
import viewAgenda from 'public/images/icons/view_agenda.svg?url';

import style from './style.module.css';

import classNames from 'classnames';
import InviteFriendsModal from 'components/InviteFriendsModal';
import LLMComparisonModal from 'components/LlmComparisonModal';
import NewSearchBtn from 'components/NewSearchBtn';
import QuestionTooltip from 'components/QuestionTooltip';
import TagContainer from 'components/TagContainer';
import AccountSettingsModal from 'containers/AccountSettingsModal';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { usePathname } from 'next/navigation';
import { useSearchProvider } from 'providers/SearchProvider';
import { isDevOrLocalhost } from 'utils/helpers';

interface SidebarProps {
  closeNavCB: (e: MouseEvent) => void;
}

const fetchLLMCompareAllowList = async () => {
  try {
    const response = await fetchWithCredentials(
      `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}/utils/fetch-llm-compare-allow-list`,
    );

    if (!response.ok) {
      return [];
    }

    return response.json();
  } catch (err) {
    return [];
  }
};

export function Sidebar({ closeNavCB }: SidebarProps) {
  const [user] = useAuth();
  const [allowList, setAllowList] = useState<string[]>([]);
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isLLMComparisonModalOpen, setIsLLMComparisonModalOpen] =
    useState(false);

  const searchProvider = useSearchProvider();
  const isStreaming = searchProvider?.search.getIsStreaming();
  const taggedSearches = searchProvider?.savedSearches.get() || [];
  const expandedTags = searchProvider?.savedSearches.getOpenedTags(false) || [];
  const pathName = usePathname();
  const isHome = pathName == '/';
  const discoveryBtnRef = useRef<HTMLButtonElement>(null);
  const discoverBtnTxt = searchProvider?.discovery.getButtonText();
  // check whether user is present in allowlist
  const showLLMComparison =
    isDevOrLocalhost() &&
    !isMobile() &&
    user?.userName &&
    allowList.includes(user.userName);

  useEffect(() => {
    if (!isDevOrLocalhost()) {
      return;
    }

    fetchLLMCompareAllowList().then((data) => {
      const allowList = data?.data?.allowList.allowedUsers || [];
      setAllowList(allowList);
    });
  }, []);

  return (
    <div className={style.container}>
      <Image
        src={closeIcon}
        className={style.closeIcon}
        alt="Close Icon"
        onClick={closeNavCB}
      />
      <Link href="/">
        <Image src={cleeaiLogoV2} className={style.logoSidebar} alt="Logo" />
      </Link>
      {!isHome && (
        <>
          <NewSearchBtn className={style.searchBtnSidebar} />
          {showLLMComparison && (
            <Button
              variant="tertiary"
              onClick={() => {
                setIsLLMComparisonModalOpen(true);
                analytics.generic(user, 'LLM comparison clicked');
              }}
              className={classNames(style.btn, style.llmCompareBtn)}
              disabled={isStreaming}
            >
              <Image src={compareIcon} alt="Compare Icon"></Image>
              Compare LLMs
            </Button>
          )}
        </>
      )}
      <div className={style.discoverContainer}>
        <Button
          variant="tertiary"
          className={classNames(style.btn, style.discoverBtn)}
          onClick={() => {
            searchProvider?.discovery.perform();
            analytics.generic(user, 'Discover button clicked');
          }}
          disabled={searchProvider?.discovery.isDisabled()}
          ref={discoveryBtnRef}
        >
          <Image src={lightbulbBlueIvy} alt="Light bulb icon"></Image>
          {discoverBtnTxt}
        </Button>
        {discoveryBtnRef.current && (
          <div className={style.discoverTooltip}>
            <QuestionTooltip
              text={
                searchProvider?.discovery.isDisabled()
                  ? 'Hang tight! I will be ready in ~30 sec'
                  : searchProvider?.discovery.getTooltip() || ''
              }
              leftOffset={discoveryBtnRef.current?.offsetWidth}
            />
          </div>
        )}
      </div>
      <div className={style.searchesHeader}>
        <Image src={viewAgenda} alt="Searches Icon" />
        <p className={style.subHeading}>Searches</p>
      </div>
      {taggedSearches.length ? (
        <div className={style.searchItemsList}>
          <ul className={style.tagSearches}>
            {taggedSearches.map((tagSearch) => {
              const { tag, searches } = tagSearch;
              return (
                <li key={tag}>
                  <TagContainer
                    searches={searches}
                    tag={tagSearch.tag}
                    closeNavCB={closeNavCB}
                    isExpanded={expandedTags.includes(tag)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <EmptyState />
      )}
      <Button
        variant="tertiary"
        onClick={() => {
          setIsInviteModalOpen(true);
          analytics.generic(user, 'Invite Friends button clicked');
        }}
        className={classNames(style.btn, style.inviteFriendsBtn)}
      >
        <Image src={inviteFriendsIcon} alt="Add Friends Icon"></Image>
        Invite friends
      </Button>
      <Button
        variant="tertiary"
        onClick={() => {
          setIsShowUserProfileModal(true);
          analytics.generic(user, 'My account button clicked');
        }}
        className={classNames(style.btn, style.myAccountBtn)}
      >
        <Image src={personOutlineIcon} alt="Person Outline Icon"></Image>
        My account
      </Button>
      <div className={style.inviteFriendsModal}>
        <InviteFriendsModal
          isModalOpen={isInviteModalOpen}
          onCloseCB={() => setIsInviteModalOpen(false)}
        />
      </div>
      <AccountSettingsModal
        isOpen={isShowUserProfileModal}
        onCloseCB={() => setIsShowUserProfileModal(false)}
      />
      {!isHome && showLLMComparison && (
        <LLMComparisonModal
          isModalOpen={isLLMComparisonModalOpen}
          onCloseCB={() => setIsLLMComparisonModalOpen(false)}
        />
      )}
    </div>
  );
}
