'use client';

import classNames from 'classnames';
import Button from 'components/Button/NewButton';
import Image from 'next/image';
import Link from 'next/link';
import style from './style.module.css';

import ModalCreateAccount from 'components/ModalCreateAccount';
import { useSearchParams } from 'next/navigation';
import { useSearchProvider } from 'providers/SearchProvider';
import Logo from 'public/images/icons/cleeaiLogoV2.svg?url';
import { useEffect } from 'react';

interface Props {
  isLogoVisible?: boolean;
}

export default function Navbar({ isLogoVisible = true }: Props) {
  const searchProvider = useSearchProvider();
  const searchParams = useSearchParams();

  useEffect(() => {
    switch (searchParams.get('action') as string) {
      case 'signup':
      case 'register':
      case 'create-account':
        searchProvider?.anonymousSearch.setOpenModal('signup');
        break;
    }
  }, [searchParams]);

  return (
    <>
      <nav
        className={classNames(
          {
            [style.navTopLogo]: isLogoVisible,
          },
          {
            [style.navbar]: !isLogoVisible,
          },
        )}
      >
        <div className={style.container}>
          <div className={style.leftArea}>
            {isLogoVisible && (
              <Link href={'/'}>
                <Image
                  src={Logo}
                  className={style.navLogoUnauth}
                  alt={'Logo'}
                />
              </Link>
            )}
          </div>
          <div className={style.rightArea}>
            <div
              className={classNames(style.unauthNavRight, {
                [style.navMWithLogo]: isLogoVisible,
              })}
            >
              <Button
                type={'submit'}
                variant={'primary'}
                onClick={() =>
                  searchProvider?.anonymousSearch.setOpenModal('signup')
                }
              >
                Sign up
              </Button>
              <Button type={'submit'} variant={'secondary'} href={'/login'}>
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </nav>
      <ModalCreateAccount
        isOpen={
          searchProvider?.anonymousSearch.getOpenModal(false) === 'signup' ??
          false
        }
        onCloseCB={() => {
          searchProvider?.anonymousSearch.setOpenModal(null);
        }}
      />
    </>
  );
}
