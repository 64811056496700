import Modal from 'components/Modal';
import CreateAccountForm from 'components/CreateAccountForm/CreateAccountForm';

import Spinner from 'components/Spinner';
import type { ModalProps } from 'components/Modal';
import { useState } from 'react';

interface Props {
  isOpen?: boolean;
  onCloseCB: ModalProps['onCloseCB'];
}

export default function ModalCreateAccount({
  isOpen = false,
  onCloseCB,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onCloseCB={onCloseCB}>
      {isLoading && <Spinner fullScreen={true} />}
      <CreateAccountForm
        onLoad={(bool) => setIsLoading(bool)}
        passwordErrorVariant="embedded"
      />
    </Modal>
  );
}
